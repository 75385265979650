import { getAppConfig } from "../../components/App/epics/appConfig/appConfig.js";
import { initAmplify } from "./initAmplify";

const appConfig = getAppConfig(),
    awsConfig = appConfig.oneWeb.trial.awsConfig;

export const AWS = require('aws-sdk/global');

const region = awsConfig.region;

export const initDemoAWS = () => {
    AWS.config.region = region;
    AWS.config.setPromisesDependency(Promise);
    initAmplify(appConfig);
};
