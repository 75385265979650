/* eslint-disable max-len */

import { $Shape } from 'utility-types';
import type { DalBatchRequest, DalDoc, DalDocType, DalPageSet } from '../../dal/flowTypes';
import { dalDocKey } from '../../dal/utils/dalDocKey';
import {
    PAGE_DATA_COMPONENT,
    SITE_DATA,
    SITE_SETTINGS,
    STYLESHEET_DATA,
    TEMPLATE_DATA_COMPONENT,
    ANALYTICS_GOALS_DATA
} from '../../dal/index';
import type { DataPageTemplateRefPage } from '../../dal/model/DataPageTemplateRef';
import { DataPageTemplateRef, DataPageTemplateSet } from '../../dal/model/index';
import { USER_PREFERENCES_DOC_ID } from '../../dal/constants';
import { DemoStorageError } from "./DemoStorageError";
import { DemoStorageDataNotFoundError } from "./DemoStorageDataNotFoundError";
import { getWbtgenTrialDb } from "./wbtgenTrialDb";
import {
    TRIAL_INSTAGRAM_ACCESS_CREDENTIALS_KEY,
    TRIAL_FACEBOOK_FEED_ACCESS_CREDENTIALS_KEY,
    TRIAL_FACEBOOK_CHAT_ACCESS_CREDENTIALS_KEY
} from "../../../server/shared/trial/constants.js";

/* ::
    import type { WbtgenTrialDBType } from "./wbtgenTrialDb"
*/

export type DemoStorageData = MapT<DalDoc>;

export class DemoStorage {
    dynamoDbStorage/*: WbtgenTrialDBType */;

    constructor() {
        this.dynamoDbStorage = getWbtgenTrialDb();
    }

    _setInitialSiteData() {
        let siteData = require('../../../resources/new.oneweb/web.data.Site.site.json');
        // TODO: Check if the following properties serve any purpose in trial. If not, remove them.
        siteData = {
            etag: "2146-9292cb9e5d362b9d880aa005630f3d30",
            fonts: [],
            rev: 1545916000426,
            time: 1545916000426,
            ...siteData
        };
        siteData = {
            ...siteData,
            origin: {
                from: "trial"
            }
        };
        return this.set(siteData)
            .then(() => {
                return siteData;
            });
    }

    get(type: DalDocType, id: string): Promise<DalDoc> {
        const key = dalDocKey(type, id);
        return this.dynamoDbStorage.get(key);
    }

    getPreferences() {
        return this.get(USER_PREFERENCES_DOC_ID, USER_PREFERENCES_DOC_ID);
    }

    getSite() {
        return this.get(SITE_DATA, 'site')
            .then(data => {
                if (data && Object.entries(data).length > 0) {
                    return data;
                }
                return {};
            }).catch(error => {
                if (error instanceof DemoStorageDataNotFoundError) {
                    return this._setInitialSiteData();
                }
                throw error;
            });
    }

    getSiteSettings() {
        return this.get(SITE_SETTINGS, 'settings')
            .then(data => {
                if (data && Object.entries(data).length > 0) {
                    return data;
                }
                return {};
            });
    }

    getPageSet(id: string): Promise<Partial<DalPageSet> | null> {
        // let returnSet = {};
        return this
            .get(PAGE_DATA_COMPONENT, id)
            .then(page => {
                if (page && page.templateId) {
                    return this
                        .get(TEMPLATE_DATA_COMPONENT, page.templateId)
                        .then(template => ({
                            page,
                            template,
                        }));
                } else {
                    throw new DemoStorageError(`Page ${id} does not have template`);
                }
            })
            .then(returnSet => {
                if (returnSet.template && returnSet.template.stylesheetId) {
                    return this
                        .get(STYLESHEET_DATA, returnSet.template.stylesheetId)
                        .then(stylesheet => ({
                            ...returnSet,
                            stylesheet,
                        }));
                } else {
                    throw new DemoStorageError(`Template ${returnSet.template.id} does not have stylesheet`);
                }
            });
    }

    getTemplateSet(id: string): Promise<Partial<DataPageTemplateSet> | null> {
        // @ts-ignore
        return this.get(TEMPLATE_DATA_COMPONENT, id)
            .then((template) => {
                if (template && template.stylesheetId) {
                    return this
                        .get(STYLESHEET_DATA, template.stylesheetId)

                        .then(stylesheet => ({
                            template,
                            stylesheet
                        }));
                } else {
                    throw new DemoStorageError(`Template ${template.id} does not have stylesheet`);
                }
            });
    }

    getTemplatePages(templateId: string): Promise<Array<DataPageTemplateRefPage>> {
        return this.dynamoDbStorage.getCurrentData()
            .then(currentData => {
                return Object
                    .keys(currentData)
                    .reduce(
                        (acc, key) => {
                            const doc = currentData[key];
                            if (doc.type === PAGE_DATA_COMPONENT && doc.templateId === templateId) {
                                return acc.concat({
                                    id: doc.id,
                                    name: doc.name,
                                    etag: doc.etag,
                                    rev: doc.rev,
                                    time: doc.time,
                                });
                            }
                            return acc;
                        },
                        [] as DataPageTemplateRefPage[], // NOSONAR
                    );
            });
    }

    getTemplatesWithPages(): Promise<Array<$Shape<DataPageTemplateRef>>> {
        return this.dynamoDbStorage.getCurrentData()
            .then(currentData => {
                return Promise.all(
                    Object
                        .keys(currentData)
                        .reduce(
                            (acc, key) => {
                                const doc = currentData[key];
                                return doc.type === TEMPLATE_DATA_COMPONENT
                                    ? acc.concat(
                                        this.getTemplatePages(doc.id).then(pages => ({
                                            id: doc.id,
                                            stylesheetId: doc.stylesheetId,
                                            name: doc.name,
                                            etag: doc.etag,
                                            rev: doc.rev,
                                            time: doc.time,
                                            pages,
                                        }))
                                    )
                                    : acc;
                            },
                            [] as any,
                        )
                );
            });
    }

    getAnalyticsGoals() {
        return this.get(ANALYTICS_GOALS_DATA, ANALYTICS_GOALS_DATA)
            .then((goalsData) => {
                return goalsData;
            }).catch(() => {
                return {};
            });
    }

    set(doc: DalDoc) {
        const key = dalDocKey(doc.type, doc.id);
        return this.dynamoDbStorage.set(key, doc);
    }

    update(doc: DalDoc) {
        const key = dalDocKey(doc.type, doc.id);
        return this.dynamoDbStorage.update(key, doc);
    }

    delete(doc: DalDoc) {
        const key = dalDocKey(doc.type, doc.id);
        return this.dynamoDbStorage.delete(key);
    }

    bulk(docs: DalBatchRequest) {
        const { add, update, delete: deleteDocs } = docs;
        return this.dynamoDbStorage.batchWrite(add, update, deleteDocs);
    }

    getData(): DemoStorageData {
        return this.dynamoDbStorage.getCurrentData();
    }

    getInstagramAccessToken = () => {
        return this.dynamoDbStorage.get(TRIAL_INSTAGRAM_ACCESS_CREDENTIALS_KEY);
    };

    deleteInstagramAccessToken = () => {
        return this.dynamoDbStorage.delete(TRIAL_INSTAGRAM_ACCESS_CREDENTIALS_KEY);
    };

    getFacebookFeedAccessToken = () => {
        return this.dynamoDbStorage.get(TRIAL_FACEBOOK_FEED_ACCESS_CREDENTIALS_KEY);
    };

    deleteFacebookFeedAccessToken = () => {
        return this.dynamoDbStorage.delete(TRIAL_FACEBOOK_FEED_ACCESS_CREDENTIALS_KEY);
    };

    updateFacebookFeedAccountConnection = (accountConnectionDetails: Array<Record<string, any>>) => {
        return this.dynamoDbStorage.storeFacebookFeedCredentials(accountConnectionDetails);
    };

    getFacebookChatAccessToken = () => {
        return this.dynamoDbStorage.get(TRIAL_FACEBOOK_CHAT_ACCESS_CREDENTIALS_KEY);
    };

    deleteFacebookChatAccessToken = () => {
        return this.dynamoDbStorage.delete(TRIAL_FACEBOOK_CHAT_ACCESS_CREDENTIALS_KEY);
    };

    updateFacebookChatAccountConnection = (messengerChatAccountDetails: Record<string, any>) => {
        return this.dynamoDbStorage.storeFacebookMessengerChatCredentials(messengerChatAccountDetails);
    };
}

let storage: null | undefined | DemoStorage;

export const getDemoStorage = (): DemoStorage => {
    if (storage) return storage;
    storage = new DemoStorage();

    return storage;
};
